import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import router from "routes";
import ThemeCustomization from "themes";

function App() {
  return (
    <HelmetProvider>
      <ThemeCustomization>
        <RouterProvider router={router} />
      </ThemeCustomization>
    </HelmetProvider>
  );
}

export default App;
