import { lazy } from "react";

// project imports
import Loadable from "components/Loadable";

const MainLayout = Loadable(lazy(() => import("layout/MainLayout")));

const Home = Loadable(lazy(() => import("views/home")));
const ColorShades = Loadable(lazy(() => import("views/color-shades")));
const ShadesOf = Loadable(lazy(() => import("views/shades-of")));
const GradientBackgrounds = Loadable(
  lazy(() => import("views/gradient-backgrounds"))
);
const ColorPalettes = Loadable(lazy(() => import("views/palettes")));
const PaletteDetails = Loadable(
  lazy(() => import("views/palettes/PaletteDetails"))
);

const GradientGenerator = Loadable(
  lazy(() => import("views/tools/gradient-generator"))
);

const Tools = Loadable(lazy(() => import("views/tools")));

const Blogs = Loadable(lazy(() => import("views/blogs")));
const BlogInfo = Loadable(lazy(() => import("views/blogs/BlogInfo")));

const AboutUs = Loadable(lazy(() => import("views/other/AboutUs")));
const ContactUs = Loadable(lazy(() => import("views/other/ContactUs")));
const PrivacyPolicy = Loadable(lazy(() => import("views/other/PrivacyPolicy")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/color-shades",
      element: <ColorShades />,
    },
    {
      path: "shades-of/:color",
      element: <ShadesOf />,
    },
    {
      path: "/gradient-backgrounds",
      element: <GradientBackgrounds />,
    },

    {
      path: "/palettes",
      element: <ColorPalettes />,
    },
    {
      path: "/palettes/:slug",
      element: <PaletteDetails />,
    },

    {
      path: "/gradient-generator",
      element: <GradientGenerator />,
    },
    {
      path: "/tools",
      element: <Tools />,
    },

    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/blog/:slug",
      element: <BlogInfo />,
    },

    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
  ],
};

export default MainRoutes;
