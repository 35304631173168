// material-ui
import { useTheme } from "@mui/material/styles";
import { CircularProgress, styled } from "@mui/material";

// styles
const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  zIndex: 9999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.9)",
  transition: "opacity 0.3s ease-in-out",
}));

// ==============================|| FULL SCREEN LOADER ||============================== //

const Loader = () => {
  const theme = useTheme();

  return (
    <LoaderWrapper>
      <CircularProgress
        size={80}
        thickness={3}
        sx={{
          color: theme.palette.primary.main,
        }}
      />
    </LoaderWrapper>
  );
};

export default Loader;
